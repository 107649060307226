import React from "react";
import { Row, Col } from "react-bootstrap";
import { CanvasWithZoom } from "../index";
import style from "./ColorPicker.module.css";
import { useScreenSize } from "../../utils";

const ColorPicker = ({
  brightness,
  index,
  onSelect,
  selectedColor,
  setSelectedColor,
}) => {
  const screenSize = useScreenSize();

  return (
    <Row className={style.imageContainer}>
      <Col className={screenSize <768 && 'p-0'}>
        <CanvasWithZoom
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          brightness={brightness}
          index={index}
          onSelect={onSelect}
        />
      </Col>
    </Row>
  );
};

export default ColorPicker;
