import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdDone } from "react-icons/md";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useAppContext } from "../../context";
import { Collapse, Footer, Slider } from "../../components";
import { useScreenSize } from "../../utils";
import { PlanSquare } from "./index";
import style from "./Pricing.module.css";
import { TextsSlider } from "./TextsSlider";

const Pricing = () => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const handleSubscribe = async () => {
    navigate("/createAccount");
    /* axios
      .post(
        "https://vps-3348494-x.dattaweb.com:5455/create-checkout-session",
        {
          lookup_key: "color_match",
        },
        {
          headers: {
            "Content-Type": "application/json",
            },
            }
            )
            .then(function (response) {
              window.location.href = response.data.url;
      })
      .catch(function (error) {
        console.log(error);
        }); */
  };

  const freeAdvantages = [
    { text: t("Match balloons to your inspiration") },
    { text: t("Find the brand of your preferred colors") },
    { text: t("Check balloon size availability") },
    { text: t("Manually create color palettes") },
    {
      text: t("Discover alternate matches*"),
      subtitle: t("*Pro feature; limited time only"),
    },
    { text: t("Access a large library of latex balloons") },
    {
      text: t(
        "Access a large library of foil balloons, confetti, tassels, florals & backdrop colors*"
      ),
      subtitle: t("*Pro feature; limited time only"),
    },
    { text: t("Export your palettes with ease.") },
  ];

  const proAdvantages = [
    { text: t("Set preferred brand preferences") },
    { text: t("Save and edit your color palettes") },
    { text: t("Add your business logo/image") },
    { text: t("Custom imports to your palette") },
    { text: t("Create a shopping list for easy sourcing") },
    { text: t("Access pre-designed palettes") },
  ];

  const paragraphs = [
    {
      title: t("Inspiration Upload"),
      text: t(
        "Upload images to instantly generate matching balloon color palettes."
      ),
    },
    {
      title: t("Customizable Palettes"),
      text: t(
        "Manually add, remove, or replace items in your palette to fit your design needs."
      ),
    },
    {
      title: t("Sourcing Details"),
      text: t(
        "View detailed sourcing information including brands and available sizes."
      ),
    },
    {
      title: t("Client Sharing"),
      text: t(
        "Easily export and share your custom balloon color palettes with clients."
      ),
    },
    /* {
      title: "Alternative Brands",
      text: "Find and compare alternative balloon brands and sizes for your designs.",
    },
    {
      title: "Accessory Database",
      text: "Access a comprehensive database of popular confetti, tassel, and foil accessories for your balloon designs.",
    }, */
    {
      title: t("Time-Saving Tool"),
      text: t(
        "Save hours on color matching and sourcing with our automated tools."
      ),
    },
    {
      title: t("User-Friendly Interface"),
      text: t(
        "Enjoy a seamless user experience tailored for balloon professionals."
      ),
    },
  ];

  const collapses = [
    {
      title: t("faq1Question"),
      text: t("faq1Answer"),
    },
    {
      title: t("faq2Question"),
      text: t("faq2Answer"),
    },
    /* {
      title: "What payment methods do you accept?",
      text: "We accept all major credit cards and utilize Stripe for secure payments.",
    },
    {
      title: "How do I cancel my subscription?",
      text: "To cancel your subscription, click on your account name and select 'Profile.' Then, navigate to 'Edit Payment Information' and you will find the option to cancel your subscription on the next screen.",
    }, */
  ];

  return (
    <Container className={`p-0 ${style.pricingContainer}`} fluid>
      {!user && (
        <Row
          className={`m-0 align-items-center justify-content-around ${style.pricingSec1}`}
        >
          <Col lg={5} className={style.pricingIntro}>
            <h1 className={style.pricingTitle}>{t("Early Access")}</h1>
            <h5 className={style.pricingSubtitle}>{t("earlyAccessCTA")}</h5>
          </Col>
          <Col xs={12} sm={8} md={6} lg={4} xl={3} className={style.pricingCTA}>
            <Button
              variant="light"
              size="lg"
              className={style.pricingBtn}
              onClick={handleSubscribe}
            >
              {t("SIGN UP FREE")}
            </Button>
            {/* <p className={style.pricingMount}>Then $6.99/month</p> */}
            {/* <p className={style.pricingInfo}>
              Billed after your 7 days free trial as $6.99/month. Renews
              automatically each month and we will notify you if the price
              increases in advance. Cancel at any time.
            </p> */}
            <p className={style.pricingInfo}>{t("No credit card required.")}</p>
          </Col>
        </Row>
      )}
      <Row
        className="m-0 px-0 justify-content-center"
        style={{ backgroundColor: "#fafafa" }}
      >
        <PlanSquare
          title="Free"
          subtitle="p/person"
          planInfo={t("freePlanInfo")}
          planBodyTitle={t("Features you’ll love:")}
          advantages={freeAdvantages}
        />
        <PlanSquare
          title="Pro"
          subtitle={t("Coming Soon")}
          planInfo={t("proPlanInfo")}
          planBodyTitle={t("Everything in Free, plus:")}
          advantages={proAdvantages}
        />
      </Row>
      <Row
        className={`m-0 px-0 py-5 justify-content-center ${style.advantages} ${
          screenSize > 768 && screenSize < 992 ? "6" : "gap-3"
        }`}
      >
        <h2 className={style.sectionTitle}>{t("Included With Every Plan")}</h2>
        {paragraphs.map((p, index) => (
          <Col key={index} md={screenSize > 768 && screenSize < 992 ? 6 : 5}>
            <Row className="justify-content-center">
              <Col xs={1} className={style.checkmarkContainer}>
                <MdDone className={style.paragraphTitle} />
              </Col>
              <Col xs={10} md={7} className={style.paragraphContainer}>
                <h4 className={style.paragraphTitle}>{p.title}</h4>
                <p className={style.paragraph}>{p.text}</p>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
      <TextsSlider />
      <Row className={`m-0 px-0 justify-content-center  ${style.questions}`}>
        <h2 className={style.sectionTitle}>
          {t("Frequently Asked Questions")}
        </h2>
        {collapses.map((c, index) => (
          <Col key={index} md={5} lg={10}>
            <Collapse index={index} title={c.title} text={c.text} />
          </Col>
        ))}
      </Row>
      <Footer />
    </Container>
  );
};

export default Pricing;
