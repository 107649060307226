import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  Button,
  Carousel,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useAppContext } from "../../context";
import { Slider, ZoomCanvas } from "../index";
import {
  Alert,
  colorMatchFn,
  drawZoom,
  loadImage,
  useBalloonsV2,
} from "../../utils";
import style from "./CanvasWithZoom.module.css";
import { useScreenSize } from "../../utils";
import { useTranslation } from "react-i18next";
import { useUpdateFirstShowMatchingMobileHelpTooltip } from "../../utils/Data/FirstToolVisit";
import matchSvg from "../../assets/match.svg";

const CanvasWithZoom = ({
  selectedColor,
  setSelectedColor,
  brightness,
  index,
  onSelect,
}) => {
  const {
    colormatch,
    setColormatch,
    images,
    user,
    setUser,
    setSelectedColorContext,
  } = useAppContext();
  const { data: balloonsData, isLoading } = useBalloonsV2();
  const canvasRefs = useRef([]);
  const zoomRef = useRef(null);
  const [showZoom, setShowZoom] = useState(false);
  const [mousePositions, setMousePositions] = useState({ x: 158, y: 187 });
  const [zoomInitialRender, setZoomInitialRender] = useState(false);
  const [selectedDragColor, setSelectedDragColor] = useState(null);
  const [matched, setMatched] = useState(false);
  const [showMatchingMobileTooltip, setShowMatchingMobileTooltip] =
    useState(false);
  const [initialInteraction, setInitialInteraction] = useState(false);
  const [showSelectedColor, setShowSelectedColor] = useState(false);
  const screenSize = useScreenSize();
  const { t } = useTranslation();
  const updateFirstShowMatchingMobileHelpTooltip =
    useUpdateFirstShowMatchingMobileHelpTooltip();
  const userPro =
    user?.stripeSubscriptionStatus === "active" ||
    user?.stripeSubscriptionStatus === "trialing";

  const handleClickOutside = (event) => {
    if (
      canvasRefs.current[index] &&
      !canvasRefs.current[index].contains(event.target)
    ) {
      //console.log('Clicked outside the canvas');
      // Aquí puedes ejecutar cualquier acción adicional
      setMousePositions({ x: null, y: null });
      // Puedes agregar aquí setShowZoom(false) si es necesario
    }
  };

  const handleTouchEnd = () => {
    setMousePositions({ x: 158, y: 187 });
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    document.addEventListener("touchmove", handleClickOutside);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("touchmove", handleClickOutside);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [mousePositions]);

  // Nueva función para obtener la posición inicial dentro del canvas
  const calculateInitialZoomPosition = (canvas) => {
    if (canvas) {
      const rect = canvas.getBoundingClientRect();
      /* const initialX = rect.left + rect.width / 2;
      const initialY = rect.top + rect.height / 2; */

      const width = canvas.width;
      const height = canvas.height;

      return {
        /* x: initialX - rect.left,
        y: initialY - rect.top, */

        x: width / 2,
        y: height / 2,
      };
    }
    return { x: null, y: null };
  };

  /* useEffect(()=>{
    if(screenSize <= 992){
      setTimeout(() => {
        setMousePositions({x: 158, y: 187});
        setShowZoom(true);
        setZoomInitialRender(true);
      }, 1000);
    }
  }, [screenSize, images, initialInteraction]); */

  // Mostrar zoom al inicio si estamos en pantallas pequeñas
  useEffect(() => {
    if (screenSize <= 992 && images.length > 0 && canvasRefs.current[index]) {
      setTimeout(() => {
        const canvas = canvasRefs.current[index];

        // Calculamos la posición inicial en el centro del canvas
        const initialZoomPosition = calculateInitialZoomPosition(canvas);

        // Solo mostramos el zoom si tenemos una posición válida
        if (initialZoomPosition.x && initialZoomPosition.y) {
          setMousePositions(initialZoomPosition);
          setShowZoom(true);
          setZoomInitialRender(true);

          /* Hacer que el circulo de match tome el primer color del centro del canvas */
          const ctx = canvas.getContext("2d");
          const pixel = ctx.getImageData(
            initialZoomPosition.x,
            initialZoomPosition.y,
            1,
            1
          ).data;

          const rgbToHex = (r, g, b) =>
            `#${r.toString(16).padStart(2, "0")}${g
              .toString(16)
              .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

          const color = rgbToHex(pixel[0], pixel[1], pixel[2]);

          setSelectedDragColor(color);
          setSelectedColorContext(color);
        }
      }, 500);
    }
  }, [screenSize, images, index]);

  /*  useEffect(()=>{
    setSelectedDragColor(null);
  }, [images]) */

  useEffect(() => {
    if (images.length > 0) {
      images.forEach((item, index) => {
        const canvas = canvasRefs.current[index];

        if (canvas) {
          // Primero cargamos la imagen y la dibujamos en el canvas
          loadImage(canvas, item, brightness).then(() => {
            /* const { width, height, left, top } = canvasRefs.current[index].getBoundingClientRect();
            setMousePositions({x: left + width / 2, y: top + height / 2});
            setShowZoom(true); */
          });
        }
      });
    }
  }, [images, brightness]);

  useEffect(() => {
    if (showZoom && zoomRef.current) {
      if (mousePositions.x !== null && mousePositions.y !== null) {
        drawZoom(zoomRef.current, canvasRefs.current[index], mousePositions);
      }
    }
  }, [showZoom, mousePositions, index]);

  /* useEffect(() => {
    const preventScroll = (e) => {
      if (showZoom) {
        e.preventDefault(); // Previene el scroll
      }
    };

    window.addEventListener("touchmove", preventScroll, { passive: false });

    return () => {
      window.removeEventListener("touchmove", preventScroll);
    };
  }, [showZoom]); */

  const handleMove = useCallback(
    (event, index, isTouch = false) => {
      setZoomInitialRender(false);
      setMatched(false);
      const canvas = canvasRefs.current[index];
      if (canvas) {
        const rect = canvas.getBoundingClientRect();

        const scaleX = canvas.width / rect.width;
        const scaleY = canvas.height / rect.height;

        const clientX = isTouch ? event.touches[0].clientX : event.clientX;
        const clientY = isTouch ? event.touches[0].clientY : event.clientY;

        const isInsideCanvas =
          clientX >= rect.left &&
          clientX <= rect.right &&
          clientY >= rect.top &&
          clientY <= rect.bottom;

        // Si no está dentro del canvas y es la primera interacción, ignorar
        if (!isInsideCanvas && !initialInteraction) {
          return;
        }

        // Si está dentro del canvas
        if (isInsideCanvas) {
          const x = (clientX - rect.left) * scaleX;
          const y = (clientY - rect.top) * scaleY;

          setMousePositions({ x, y });
          setShowZoom(true);

          // Si es la primera interacción válida dentro del canvas
          if (!initialInteraction) {
            setInitialInteraction(true); // Marca la primera interacción dentro del canvas
          }
        } else {
          setShowZoom(false); // Esconder zoom si está fuera del canvas
        }

        const x = (clientX - rect.left) * scaleX;
        const y = (clientY - rect.top) * scaleY;

        /* setMousePositions({ x, y });
        setShowZoom(true); */

        const ctx = canvas.getContext("2d");
        const pixel = ctx.getImageData(x, y, 1, 1).data;

        const rgbToHex = (r, g, b) =>
          `#${r.toString(16).padStart(2, "0")}${g
            .toString(16)
            .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

        const color = rgbToHex(pixel[0], pixel[1], pixel[2]);

        setSelectedDragColor(color);
        setSelectedColorContext(color);
        setShowSelectedColor(true);
      }
    },
    [setMousePositions, setShowZoom, initialInteraction]
  );

  const handleMouseLeave = useCallback(() => {
    setMousePositions({ x: null, y: null });
    setShowZoom(false);
    setShowSelectedColor(false);
  }, [setMousePositions, setShowZoom]);

  useEffect(() => {
    const addListeners = (canvas, index) => {
      const moveHandler = (event) => handleMove(event, index);
      const leaveHandler = handleMouseLeave; // Usa la referencia de handleMouseLeave

      canvas.addEventListener("mousemove", moveHandler);
      canvas.addEventListener("mouseleave", leaveHandler);

      return { moveHandler, leaveHandler }; // Retornar handlers para la limpieza
    };

    const removeListeners = (canvas, { moveHandler, leaveHandler }) => {
      canvas.removeEventListener("mousemove", moveHandler);
      canvas.removeEventListener("mouseleave", leaveHandler);
    };

    const currentCanvasRefs = canvasRefs.current;

    const listeners = currentCanvasRefs
      .map((canvas, index) => {
        if (canvas) return addListeners(canvas, index);
        return null;
      })
      .filter(Boolean); // Filtrar listeners no nulos

    return () => {
      listeners.forEach((listeners, index) => {
        if (currentCanvasRefs[index]) {
          removeListeners(currentCanvasRefs[index], listeners);
        }
      });
    };
  }, [images, handleMove, handleMouseLeave]);

  const handleCanvasPress = useCallback(
    (event, index, isTouch = false) => {
      if (screenSize <= 992) {
        setShowZoom(false);
        return;
      }
      const canvas = canvasRefs.current[index];
      const ctx = canvas.getContext("2d");

      const rect = canvas.getBoundingClientRect();

      // Verificar si el evento ocurrió dentro de los límites del canvas
      const clientX = isTouch ? event.changedTouches[0].clientX : event.clientX;
      const clientY = isTouch ? event.changedTouches[0].clientY : event.clientY;

      const isInsideCanvas =
        clientX >= rect.left &&
        clientX <= rect.right &&
        clientY >= rect.top &&
        clientY <= rect.bottom;

      // Ignorar si se toca fuera del canvas, especialmente si no hubo interacción previa
      if (!isInsideCanvas && !initialInteraction) {
        return;
      }

      // Si es la primera interacción válida dentro del canvas
      if (isInsideCanvas && !initialInteraction) {
        setInitialInteraction(true); // Marca la primera interacción dentro del canvas
      }

      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;

      let x, y;
      if (isTouch) {
        const touch = event.changedTouches[0];
        x = (touch.clientX - rect.left) * scaleX;
        y = (touch.clientY - rect.top) * scaleY;
      } else {
        x = event.nativeEvent.offsetX * scaleX;
        y = event.nativeEvent.offsetY * scaleY;
      }

      const pixel = ctx.getImageData(x, y, 1, 1).data;

      // rgbToHex dentro de la funcion para que no se cree en cada renderizado.
      const rgbToHex = (r, g, b) =>
        `#${r.toString(16).padStart(2, "0")}${g
          .toString(16)
          .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

      const color = rgbToHex(pixel[0], pixel[1], pixel[2]);

      if (color === selectedColor) {
        Alert.error("You have already clicked this color.");
      } else {
        setSelectedColor(color);
        let userPro =
          user.stripeSubscriptionStatus === "active" ||
          user.stripeSubscriptionStatus === "trialing"
            ? true
            : false;

        colorMatchFn(
          balloonsData,
          isLoading,
          colormatch,
          setColormatch,
          color,
          /* userPro */
        );
      }
    },
    [
      selectedColor,
      setSelectedColor,
      balloonsData,
      isLoading,
      colormatch,
      setColormatch,
      initialInteraction,
    ]
  );

  const handleConfirmMatchColor = async (selectedColor) => {
    setMatched(true);
    if (
      (await colorMatchFn(
        balloonsData,
        isLoading,
        colormatch,
        setColormatch,
        selectedColor,
        /* userPro */
      )) !== false
    ) {
      Alert.success("Matched! Scroll to view");
    }
  };

  // Para que el Tooltip no se rompa cuando cambia de texto corto a largo
  /* const UpdatingTooltip = React.forwardRef(
    ({ popper, children, show: _, ...props }, ref) => {
      useEffect(() => {
        popper.scheduleUpdate();
      }, [matched]);

      return (
        <Tooltip ref={ref} body {...props}>
          {children}
        </Tooltip>
      );
    },
  ); */

  useEffect(() => {
    if (user) {
      if (user.firstShowMatchingMobileHelpTooltip) {
        setShowMatchingMobileTooltip(true);
      }
    }
  }, [user]);

  const handleCloseMatchingMobileHelpTooltip = () => {
    setShowMatchingMobileTooltip(false);

    setUser((prevState) => ({
      ...prevState,
      firstShowMatchingMobileHelpTooltip: false,
    }));
    updateFirstShowMatchingMobileHelpTooltip.mutate(user.email);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
      }}
      className={screenSize >= 992 ? style.canvasDesktopContainer : null}
    >
      {selectedDragColor && screenSize >= 992 && showSelectedColor && (
        <>
          <div className={style.colorCircleContainer}>
            <div
              style={{
                backgroundColor: selectedDragColor && selectedDragColor,
              }}
              className={style.squareDragColor}
              onClick={() => handleConfirmMatchColor(selectedDragColor)}
            ></div>
          </div>
        </>
      )}

      <Slider
        index={index}
        onSelect={onSelect}
        controls={false}
        indicators={false}
        interval={null}
        touch={false}
      >
        {images.map((_, index) => (
          <Carousel.Item key={index}>
            <canvas
              ref={(el) => (canvasRefs.current[index] = el)}
              alt="Canvas"
              className={style.canvas}
              onClick={(event) => handleCanvasPress(event, index)}
              onMouseMove={(event) => handleMove(event, index)}
              onMouseLeave={handleMouseLeave}
              //onTouchStart={(event) => handleCanvasPress(event, index, true)}
              onTouchMove={(event) => handleMove(event, index, true)} // Usar handleMove adaptado para touch
              onTouchEnd={(event) => {
                /* handleCanvasPress(event, index, true); */
                setShowZoom(false);
                handleMouseLeave();
              }}
            />
          </Carousel.Item>
        ))}
      </Slider>

      {/* {selectedDragColor && screenSize >= 992 &&
      <>
      <div>
        <div
        style={{ backgroundColor: selectedDragColor && selectedDragColor }}
        className={style.squareDragColor}
        onClick={() => handleConfirmMatchColor(selectedDragColor)}
        >
        </div>
      </div>
      </>
      } */}

      {selectedDragColor && screenSize <= 992 && (
        <OverlayTrigger
          placement="top"
          show={showMatchingMobileTooltip}
          flip={true}
          overlay={
            <Tooltip className={style.matchingTooltip} id="matching-tooltip">
              <Row className="d-flex align-items-center">
                <Col xs={8}>
                  {/* <p className={style.matchingTooltipTitle}>Find Balloon Match</p> */}
                  <p className={style.matchingTooltipText}>
                    {t(
                      "Drag your finger over image to pick a color, then tap 'Match'."
                    )}
                  </p>
                </Col>
                <Col xs={4}>
                  <Button
                    className={style.matchingTooltipBtn}
                    onClick={handleCloseMatchingMobileHelpTooltip}
                  >
                    Got it
                  </Button>
                </Col>
              </Row>
            </Tooltip>
          }
        >
          <div
            style={{ backgroundColor: selectedDragColor && selectedDragColor }}
            className={style.squareDragColor}
            onClick={() => handleConfirmMatchColor(selectedDragColor)}
          >
            <Image src={matchSvg} width={"85%"} />
          </div>
        </OverlayTrigger>
      )}

      {showZoom &&
        mousePositions.x &&
        mousePositions.y &&
        canvasRefs.current && (
          <ZoomCanvas
            zoomRef={zoomRef}
            ratio={screenSize >= 992 ? "60px" : "90px"}
            border={"whitesmoke"}
            showZoom={showZoom}
            origCanvasRef={canvasRefs.current[index]}
            initialMousePositions={mousePositions}
            zoomInitialRender={zoomInitialRender}
            mousePositions={mousePositions}
          />
        )}
    </div>
  );
};

export default CanvasWithZoom;