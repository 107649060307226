import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import { FaTrash, FaCheck } from "react-icons/fa";
import { Container, Spinner, Row, Col, Button } from "react-bootstrap";
import { Datagrid, ModalWindow } from "../../../components";
import { Toaster } from "sonner";
import {
  useImagesUploaded,
  useDeleteUploadedImages,
  useDeleteUploadedImagesStorage,
} from "../../../utils";
import { useAppContext } from "../../../context";
import style from "../Dashboard.module.css";

const UploadedImages = () => {
  const { data: imagesUploaded, isLoading } = useImagesUploaded();
  const { mutate: deleteUploadedImage, isLoading: deleting } =
    useDeleteUploadedImages();
  const deleteImageFromStorage = useDeleteUploadedImagesStorage();
  const { user } = useAppContext();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState("");

  const handleDeleteImage = (item) => {
    try {
      deleteUploadedImage({ user: item.user, imagePath: item.path });
      deleteImageFromStorage.mutate({imagePath: item.path});
      setTimeout(() => {
        setConfirmDelete(false);
      }, 1500);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelDelete = () => {
    setConfirmDelete(false);
    setItemToDelete("");
  };

  const handleShowConfirmDelete = (item) => {
    setConfirmDelete(true);
    setItemToDelete(item);
  };

  const columns = [
    {
      name: "Image Uploaded",
      selector: (row) => (row.images ? row.images : row.imageURL),
      cell: (row) => (
        <div>
          {row.images ? (
            row.images.map((imagen, index) => (
              <img
                key={index}
                src={imagen.downloadURL ? imagen.downloadURL : imagen}
                alt={`imagen-${index}`}
                style={{ width: "150px", margin: "5px" }}
              />
            ))
          ) : (
            <img src={row.imageURL} style={{ width: "150px", margin: "5px" }} />
          )}
        </div>
      ),
      grow: 3,
    },
    {
      name: "Uploaded At",
      selector: (row) => {
        if (row.uploadedAt) {
          const date = new Date(row.uploadedAt.seconds * 1000);
          return date;
        }
        return null;
      },
      format: (row) => {
        if (row.uploadedAt) {
          const date = new Date(row.uploadedAt.seconds * 1000);
          const options = {
            hour12: false,
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          };
          return date.toLocaleString("en-US", options);
        }
        return "";
      },
      sortable: true,
      id: "uploadedAt",
    },
    {
      name: "User",
      selector: (row) => row.user,
      sortable: true,
      grow: 2,
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          row.path && (
            <>
              <Button
                variant="dark"
                onClick={() => handleShowConfirmDelete(row)}
              >
                <FaTrash />
              </Button>
            </>
          )
        );
      },
    },
  ];

  return (
    <Container className={`p-5 ${style.container}`}>
      <Row>
        <Col>
          <h1 className={style.title}>Uploaded Images List</h1>
        </Col>
      </Row>
      <Row className={`gap-5 ${style.body} align-items-center`}>
        <Col>
          {!isLoading ? (
            <Datagrid
              columns={columns}
              data={imagesUploaded}
              imagesUploadedTable={true}
            />
          ) : (
            <Col style={{ textAlign: "center" }}>
              <Spinner />
            </Col>
          )}
        </Col>
        <Toaster richColors position="bottom-center" />
      </Row>

      <ModalWindow
        show={confirmDelete}
        onHide={() => setConfirmDelete(false)}
        centered
        title="Confirm delete?"
        closeButton={true}
        body={
          <Row>
            <div className="justify-content-center d-flex center gap-4">
              <Button
                size="sm"
                variant="success"
                onClick={() => handleDeleteImage(itemToDelete)}
                disabled={deleting}
              >
                {deleting ? "Deleting..." : <FaCheck />}{" "}
              </Button>
              <Button size="sm" variant="danger" onClick={handleCancelDelete}>
                <MdCancel />
              </Button>
            </div>
          </Row>
        }
      />
    </Container>
  );
};

export default UploadedImages;
